<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t('message.show_conclusions') }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
              >  {{ $t('message.save_and_close') }}</el-button>
              <el-button @click="close()">{{ $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <div class="modal-body" v-loading="loadingData">
          <el-row>
            <el-col class="ml-5" :span="8">
              {{ $t('message.doctor_name') }} :
              <b>{{ model.doctor ? (model.doctor.name + ' ' + (model.doctor.surname ? model.doctor.surname :'') + ' ' + (model.doctor.patronymic ? model.doctor.patronymic :'')) : ''}}</b>
            </el-col>
            <el-col :span="8">
              {{ $t('message.date') }} :
              <b>{{ model.date_time }}</b>
            </el-col>
            <el-divider></el-divider>
            <el-col :span="24">
              <el-card class="ml-5" style="width:826px" shadow="always">
                <el-card shadow="always">
                  <Tinymce :id="tinymceId" class="naw_Editor" v-model="form.content" :height="800" />
                </el-card>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Tinymce from "@/components/Tinymce";
import form from '@/utils/mixins/form';
import drawerChild from '@/utils/mixins/drawer-child';

export default {
  mixins: [form, drawerChild],
  props: ['currentPatient', 'selected'],
  components: {
    Tinymce
  },
  data() {
    return {
      tinymceId: 'create_conslusion_tiny3',
      editorConfig: {},
      loadingButton:false,
      loadingData: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "hospitalizationConclusions/rules",
      model: "hospitalizationConclusions/model",
      columns: "hospitalizationConclusions/columns"
    })
  },
  methods: {
    ...mapActions({
      editModel: 'hospitalizationConclusions/show',
      update: "hospitalizationConclusions/update"
    }),
    submit(close = true) {
      this.loadingButton = true;
      this.update({ id: this.form.id, data: this.form })
        .then(res => {
          this.loadingButton = false;
          this.$alert(res);
          this.parent().listChanged()
          if (close == true) {
              this.close();
          }
        })
        .catch(err => {
          this.loadingButton = false;
          this.$alert(err);
        });
    },
    afterOpened(){
      if (this.selected && !this.loadingData) {
          this.loadingData = true;
          this.editModel(this.selected.id)
              .then(async (res) => {
                  this.loadingData = false;
                  this.form = JSON.parse( JSON.stringify(this.model));
                  this.setToTinyContent(this.form.content)
              })
              .catch(err => {
                  this.loadingData = false;
                  this.$alert(err)
              });                    
      }
    },
    afterClosed(){
      this.form.content = '';
      this.setToTinyContents()
    },
    setToTinyContents(){
      window.tinymce.get(this.tinymceId).setContent(this.form.content || '')
    },
    setToTinyContent(content){
      window.tinymce.get(this.tinymceId).setContent(content)
    },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
  }
};
</script>
